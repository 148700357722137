<template>
  <app-page>
    <div class="landing-menu-outside" @click="isMenuOpen = false" v-if="isMenuOpen"></div>

    <div v-if="merchant && merchant.options.websiteActivated" class="">
      <div class="merchant-landing__background" :style="{'backgroundImage': 'url('+merchant.backgroundImages[0]+')'}">
        <div class="gradient"></div>
      </div>
      <div class="merchant-landing__header">
        <div class="header__actions">
          <button @click="isMenuOpen = true" class="menu">
            <i class="material-icons notranslate notranslate">menu</i>
          </button>

          <div class="landing__floating-menu" :class="{'landing__floating-menu-active': isMenuOpen}">
            <div class="floating-menu__header">
              <strong>{{merchant.name}}</strong>
              <button @click="isMenuOpen = false">
                <i class="material-icons notranslate">close</i>
              </button>
            </div>
            <div class="floating-menu__content">
              <div class="item" @click="scrollToElement('discover')">Découvrir</div>
              <a :href="BookingLink+'/'+merchant.slug" v-if="merchant.options.bookingEnabled" @click="showMenu = false" class="item">Réserver une table</a>
              <div class="item" @click="openMerchant('on_site')" v-if="merchant.options.onSiteEnabled">Consulter le menu sur place</div>
              <div class="item" @click="openMerchant('delivery')" v-if="merchant.options.deliveryEnabled">Commander en livraison</div>
              <div class="item" @click="openMerchant('pickup')" v-if="merchant.options.pickupEnabled">Commander en Click & Collect</div>
              <div class="item" @click="openMerchant('on_site_counter')" v-if="merchant.options.onsiteCounterEnabled">Commander au comptoir</div>
              <div @click="scrollToElement('social_networks')" v-if="merchant.social.length > 0 || merchant.platforms.length > 0" class="item">Réseaux sociaux</div>
              <div @click="scrollToElement('infos')" class="item">Infos pratiques</div>
            </div>
          </div>


          <div></div>
          <button v-if="shareBtn" @click="share">
            <i class="material-icons notranslate">share</i>
          </button>
        </div>

        <div class="header__hero">
          <div class="hero__logo"><img :src="merchant.logo.path" alt=""></div>

          <div class="hero__infos">
            <h1>{{merchant.name}}</h1>
            <p>{{merchant.address.address}}, {{merchant.address.zip}} {{merchant.address.city}}</p>
            <p>{{merchant.categories}}</p>
          </div>

          <div class="hero__available" @click="scrollToElement('infos')" >
            <merchant-availability></merchant-availability>
          </div>

          <div class="hero__buttons">
            <a :href="'tel:'+merchant.phone" v-if="merchant.showPhone">{{merchant.parsedPhone}}</a>
            <a :href="BookingLink+'/'+merchant.slug"  v-if="merchant.options.bookingEnabled">Réserver une table</a>
          </div>

        </div>
      </div>



      <div class="merchant-landing__order-actions">
        <div class="mb-3" v-if="merchant.announcements.some(an => an.position === 'website_front')">
          <announcement position="website_front"></announcement>
        </div>
        <button class="pickup" @click="openMerchant('on_site')" v-if="merchant.options.onSiteEnabled">
          <i class="material-icons notranslate">restaurant</i>
          Consulter le menu sur place
        </button>
        <button class="pickup" @click="openMerchant('on_site_counter')"v-if="merchant.options.onsiteCounterEnabled">
          <i class="material-icons notranslate">dinner_dining</i>
          Commander au comptoir
        </button>

        <button class="delivery" @click="openMerchant('delivery')" v-if="merchant.options.deliveryEnabled">
          <i class="material-icons notranslate">directions_bike</i>
          Commander en livraison
        </button>

        <button class="pickup" @click="openMerchant('pickup')" v-if="merchant.options.pickupEnabled">
          <i class="material-icons notranslate">local_mall</i>
          Commander en Click&Collect
        </button>


      </div>

      <div class="merchant-landing__merchant-infos" id="discover">
        <h1>{{merchant.name}}</h1>
        <h2>{{merchant.subtitle}}</h2>
        <p>{{!showBigDescription ? merchant.descriptionTrimmed : merchant.description}}<a @click.prevent="showBigDescription = true" href="#" v-if="merchant.bigDescription && !showBigDescription">Voir plus</a><a @click.prevent="showBigDescription = false" href="#" v-if="merchant.bigDescription && showBigDescription">Voir moins</a></p>
      </div>
      <div class="merchant-landing__highlights" v-if="highlights.length > 0">

        <div class="product-container">
          <div class="product" @click="openLightBox(i)" v-for="(p,i) in highlights" :key="i" :style="{'backgroundImage': 'url('+p.imageLarge.path+')'}"></div>
        </div>
      </div>

      <div class="merchant-landing__links" id="social_networks">
        <div class="social-networks">

          <div class="link" @click="openSocial(s.url)" v-for="(s,i) in merchant.social" :key="i">
            <div class="link__image">
              <img class="logo" v-if="s.socialNetwork === 'facebook'" src="/img/social/fb.svg" alt="">
              <img class="logo" v-else-if="s.socialNetwork === 'twitter'" src="/img/social/twitter.svg" alt="">
              <img class="logo" v-else-if="s.socialNetwork === 'messenger'" src="/img/social/messenger.svg" alt="">
              <img class="logo" v-else-if="s.socialNetwork === 'instagram'" src="/img/social/instagram.svg" alt="">
              <img class="logo" v-else :src="s.logo.path" alt="">
            </div>
            <span>{{s.socialNetwork}}</span>
          </div>

        </div>

        <div class="social-networks" v-if="merchant.platforms.length > 0">

          <div class="link" @click="openSocial(merchant.platforms.find(p => p.socialNetwork === 'ubereat').url)" v-if="merchant.platforms.find(p => p.socialNetwork === 'ubereat')">
            <div class="link__image">
              <img class="platform-logo" src="/img/ubereat.svg">
            </div>
            <span>Uber eats</span>
          </div>

          <div class="link" @click="openMerchant('delivery')">
            <div class="link__image">
              <img class="platform-logo" src="/img/delicity_scare.svg">
            </div>
            <span>Delicity</span>
          </div>

          <div class="link" @click="openSocial(merchant.platforms.find(p => p.socialNetwork === 'deliveroo').url)" v-if="merchant.platforms.find(p => p.socialNetwork === 'deliveroo')">
            <div class="link__image">
              <img class="platform-logo" src="/img/deliveroo.svg">
            </div>
            <span>Deliveroo</span>
          </div>
        </div>
      </div>

      <div class="merchant-landing__map" @click="openMap" :style="{'backgroundImage': 'url('+merchant.map.path+')'}"></div>

      <div class="merchant-landing__footer" id="infos">
        <div class="footer__address">
          <strong>{{merchant.name}}</strong>
          <span>{{merchant.address.address}}</span>
          <span>{{merchant.address.address2}}</span>
          <span>{{merchant.address.zip}} {{merchant.address.city}}</span>
          <a :href="'tel:'+merchant.phone" v-if="merchant.showPhone">{{merchant.parsedPhone}}</a>
        </div>
        <div class="footer__schedules">
          <table class="week-hours">
            <tr v-for="shift in shifts">
              <td class="pt-2"><b>{{shift[0].weekDayFormated}}</b></td>
              <td class="hours pt-2">
                <span v-if="s.start && s.end" v-for="s in shift" class="mr-4">{{s.start}}-{{s.end}}</span>
                <span v-if="!s.start || !s.end" v-for="s in shift" class="closed">Fermé</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="footer__love">
          <span>Fait avec<img src="/img/heart.png">à Nice</span>
          <a href="https://delicity.com" target="_blank" class="platform">
            <img src="/img/delicity_footer.svg">
          </a>
        </div>
        <div class="footer__copyright">
          <span>© 2022 {{merchant.host}} · <a href="https://pro.delicity.com/cgv/" target="_blank">CGV</a> · <a href="https://pro.delicity.com/legal/" target="_blank">Mentions légales</a></span>
        </div>
      </div>

    </div>
    <div v-else class="loading-screen">
      <loader></loader>
    </div>


    <vue-easy-lightbox
        :escDisabled="true"
        :moveDisabled="true"
        :visible="isLightBoxVisible"
        :imgs="lightBoxImages"
        :index="lightBoxIndex"
        @hide="isLightBoxVisible = false">
      <template v-slot:toolbar="{ toolbarMethods }"><div></div></template>
    </vue-easy-lightbox>
  </app-page>
</template>

<script>
import '@/scss/app_mobile.scss'
import {mapGetters, mapActions} from 'vuex';
import AppPage from '@/mobileComponents/Page'
import Loader from "@/components/Loader";
import VueEasyLightbox from 'vue-easy-lightbox'
import MerchantAvailability from "@/components/MerchantAvailability";
import config from "@/config";
import Announcement from "@/mobileComponents/Merchant/Announcement";

export default {
  data() {
    return {
      shareBtn: false,
      isMenuOpen: false,
      isLightBoxVisible: false,
      lightBoxImages: [],
      lightBoxIndex: 0,
      showBigDescription: false,
      BookingLink: config.bookingLink
    }
  },
  components: {AppPage, Loader, VueEasyLightbox, MerchantAvailability, Announcement},
  methods: {
    ...mapActions(['getMerchant']),
    updateFavicon(){
      if(this.merchant && this.merchant.metadata){
        document.getElementById('ico').href = this.merchant.metadata.icon;
        document.getElementById('i180').href = this.merchant.metadata.icon180x180;
        document.getElementById('i32').href = this.merchant.metadata.icon32x32;
        document.getElementById('i16').href = this.merchant.metadata.icon16x16;
      }
    },
    openMerchant(type) {
      this.$router.push('/order?type='+type);
    },
    openSocial(url){
      window.open(url, '_blank');
    },
    callMerchant(phone){
      window.open('tel:'+phone);
    },
    openMap(){
      window.open('https://www.google.com/maps?q='+this.merchant.name+' '+this.merchant.address.address+', '+this.merchant.address.city, '_blank');
    },
    scrollToElement(id){
      document.getElementById(id).scrollIntoView({behavior: 'smooth'});
      this.isMenuOpen = false;
    },
    share(){
      if (navigator.share) {
        navigator.share({
          text: this.merchant.name + ' à ' + this.merchant.address.city,
          url: location.href
        }).then(() => {
        }).catch(console.error);
      }
    },
    openLightBox(index){
      this.lightBoxImages = this.highlights.map(h => h.imageLarge.path);
      this.lightBoxIndex = index;
      this.isLightBoxVisible = true;
    }
  },
  computed: {
    ...mapGetters(['merchant', 'shifts', 'products']),
    highlights() {
      return this.products.filter(p => p.highlighted === true && p.imageLarge.path);
    }
  },
  async mounted() {
    if (navigator.share) {
      this.shareBtn = true;
    }
    const slug = this.$route.params.merchantSlug ? this.$route.params.merchantSlug : location.host;
    await this.getMerchant({slug: slug});
    this.updateFavicon();
    if(this.merchant.options.websiteActivated === false){
      location.href = '/order';
    }

    let root = document.documentElement;
    root.style.setProperty('--theme-primary', this.merchant.theme.primary);
    root.style.setProperty('--theme-secondary', this.merchant.theme.secondary);
  }
}
</script>
